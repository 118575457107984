'use strict';

// UIKit Components
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);


// Custom components
// import { Component } from './components/Component';

/**
  * Creates a new instance of Client application.
  * @name Client
 */
class Client {
  constructor() {
    this.body = document.getElementsByClassName('body');
    this.init();
  }

  init() {
    let _this = this,
        path = require('path');


    document.addEventListener('click', function (event) {

      // If the clicked element doesn't have the right selector, bail
      if (!event.target.matches('.close')) return;

      // Don't follow the link
      event.preventDefault();

      // Log the clicked element in the console
      console.log('click');

    }, false);

    if (document.querySelector('.uk-navbar-nav') !== null) {
      var button = document.querySelector('[data-open]');
      var quickReservation = document.querySelector('.quick-reservation');
      var confirmButton = document.querySelector('.quick-reservation .uk-button');

      button.addEventListener('click', function(){
        UIkit.modal(quickReservation).show().then(function(){
          console.log('Modal open');
        });
      });

      confirmButton.addEventListener('click', function () {
        UIkit.modal(quickReservation).hide().then(function () {
          console.log('Confirm');
          console.log('Modal close');
        });
      });
    }

    // if (document.querySelector('.room-list') !== null) {
    //   var rooms = document.querySelectorAll('.room-item .desc');
    //   rooms.forEach(function (el) {
    //     el.addEventListener('click', function () {
    //       el.classList.toggle('is-active');
    //       console.log('Selected: ' + el.dataset.room);
    //     });
    //   });
    // }

    // if (document.querySelector('.time') !== null) {
    //   var el = document.querySelectorAll('.time');
    //   el.forEach(function (el, index) {
    //     el.innerHTML = el.dataset.time;
    //   });
    // }

    // if (document.querySelector('.booking-calendar') !== null) {
    //   var EventButton = document.querySelectorAll('.booking-calendar .uk-button');
    //   var BookingBar = document.getElementById('reservation-bar');
    //   if (EventButton) {
    //     EventButton.forEach(function (el) {
    //       var EventDate = el.querySelectorAll(".date");
    //       if (EventDate && el.dataset.date) {
    //         var date = EventDate[0];
    //         date.innerHTML = el.dataset.date;
    //       }

    //       el.addEventListener('click', () => {
    //         UIkit.offcanvas(BookingBar).show();
    //         console.log(el.dataset.date);
    //       });
    //     });
    //   }
    // }

    // if (document.getElementById('invoice') !== null) {
    //   var
    //     checkBox = document.getElementById("invoice"),
    //     field = document.getElementById('field');

    //     function invoiceStatus(checkBox, field) {
    //       if (checkBox.checked) {
    //         field.style.display = "none";
    //       } else {
    //         field.style.display = "block";
    //       }
    //     }

    //     invoiceStatus(checkBox, field);

    //   checkBox.addEventListener('change', function () {
    //     invoiceStatus(this, field);
    //   });
    // }

  }
}



document.addEventListener('DOMContentLoaded', function () {
  let client = new Client();
});
